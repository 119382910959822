import { Dispatch, FC, SetStateAction } from "react";

type Props = {
  setSearch: Dispatch<SetStateAction<string>>;
};

const Searchbar: FC<Props> = ({ setSearch }) => {
  return (
    <div className="p-3 w-full border-y flex justify-center">
      <input
        onChange={(e) => setSearch(e.target.value)}
        className="p-2 rounded-full outline-none w-[90%] h-[36px] text-sm text-black font-nunito bg-red"
        placeholder="Search..."
        type="text"
        name="text"
        spellCheck="false"
      />
    </div>
  );
};

export default Searchbar;
