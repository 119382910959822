const Terms = () => {
    return (
        <p className='text-black text-center mt-3 mb-5 font-nunito'>
            {'By signing up, you agree to our '}
            <span className='text-terms-text duration-200 hover:text-pink cursor-pointer'>
                Terms,{' '}
            </span>
            <span className='text-terms-text duration-200 hover:text-pink cursor-pointer'>
                Privacy Policy
            </span>
            {' and '}
            <span className='text-terms-text duration-200 hover:text-pink cursor-pointer'>
                Cookies Policy
            </span>
        </p>
    )
}

export default Terms