import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'

import { RootState } from '../../redux/store'
import PageInfo from '../../components/layout/ContentArea/PageInfo'
import { useEffect, useRef, useState } from 'react'
import { getUser, updateUser, uploadUserImage } from '../../services/userService'
import BasicButton from '../../components/buttons/BasicButton'
import { toast, Toaster } from 'react-hot-toast'
import { API_BASE_URL } from '../../utils/constants'
import { useNavigate } from 'react-router-dom';

const ProfileEdit = () => {
    const user = useSelector((state: RootState) => state.auth.user);
    const [details, setDetails] = useState<User>();
    const [image, setImage] = useState<any>();
    const [name, setName] = useState<string>('');
    const inputRef = useRef<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            const result = await getUser(user?.id!);
            setDetails(result.user);
        };

        fetchUser();
    }, [user?.id]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const username = e.target.username.value;
        const aboutme = e.target.about.value;
        
        if (!username) return;
        if (image) {
            const secureUrl = await uploadUserImage(image);
            var { statusCode, message } = await updateUser(user?.id!, { username, aboutme, image: secureUrl });
        } else {
            var { statusCode, message } = await updateUser(user?.id!, { username, aboutme });
        }

        if (statusCode === '200') {
            toast.success(message, {
                duration: 3000,
                position: 'bottom-center',
                style: {
                    backgroundColor: '#353535',
                    color: '#fff'
                }
            });

            setTimeout(() => {
                navigate('/');
            }, 3000);
            
            return;
        }

        return toast.error(message, {
            duration: 3000,
            position: 'bottom-center',
            style: {
                backgroundColor: '#353535',
                color: '#fff'
            }
        });
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    const handleChange = (e: any) => {
        const imageFile = e.target.files[0];
        setName(imageFile.name);

        if (imageFile && FileReader) {
            const fr = new FileReader();
            fr.onload = () => {
                setImage(fr.result)
            }
            fr.readAsDataURL(imageFile);
        }
    };

  let imgPath;
  if (details?.image?.includes("avataaars.io")) {
    imgPath = details?.image;
  } else {
    imgPath = API_BASE_URL + '/api/v1/files/' + details?.image;
  }

    return (
        <section className='h-full bg-white font-nunito'>
            <PageInfo isChannel={false} name='Edit Profile' />
            <form className='w-full flex flex-col items-center justify-around py-5 bg-white' onSubmit={handleSubmit} action='POST'>
                <div className='flex md:flex-row flex-col'>
                    <LazyLoadImage
                        className='rounded-full md:w-52 md:h-52 sm:h-[9rem] sm:w-[9rem] object-cover cursor-pointer'
                        src={image ? image : imgPath}
                        alt='user'
                        effect='blur'
                    />
                    <input onChange={handleChange} ref={inputRef} type="file" name="image" hidden accept='image/png, image/jpeg' />
                </div>
                <div className='mt-10 flex flex-col md:w-[400px] sm:w-[87%]'>
                    <label className='text-xl font-semibold text-black font-nunito' htmlFor="username">Username:</label>
                    <input
                        minLength={5}
                        maxLength={20}
                        placeholder='Username'
                        className='bg-[#F0F6F7] text-black outline-none rounded-md p-2'
                        type="text"
                        name='username'
                        defaultValue={details?.username}
                    />
                    <label className='text-xl font-semibold mt-5 text-black' htmlFor="about">About:</label>
                    <textarea
                        spellCheck={false}
                        maxLength={250}
                        placeholder='Write about yourself...'
                        className='bg-[#F0F6F7] text-black outline-none rounded-md p-2 mb-5 resize-none'
                        name="about"
                        cols={30}
                        rows={10}
                        defaultValue={details?.aboutme}
                    ></textarea>
                    <BasicButton type='submit'>Save</BasicButton>
                </div>
            </form>
            <Toaster />
        </section>
    )
}

export default ProfileEdit