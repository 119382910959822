import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../../assets/speech-bubble.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="w-full h-full flex items-center justify-center bg-white">
      <div className="flex flex-col items-center p-3">
        <LazyLoadImage
          src={Logo}
          alt="logo"
          effect="blur"
          className="max-w-[500px]"
        />
        <h1 className="text-5xl text-center mt-3 px-3 text-black uppercase underline font-nunito">
          CHAT APP
        </h1>
        <p className="text-xl text-center mt-3 px-3 text-black font-nunito">
          Modern and fast chat app
        </p>
        <ul className="list-disc text-xl text-black mt-6">
          <li>Conversate one on one or create channels.</li>
          <li>Add member or block someone.</li>
          <li>Chat with your team members.</li>
        </ul>
        <Link
          to="/addfriend"
          className="bg-[#66a3a6] rounded-md px-5 py-3 text-xl mt-8 font-nunito"
        >
          Find Your Team Members
        </Link>
      </div>
    </div>
  );
};

export default Home;
