import Cookies from "js-cookie";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import Logo from "../../assets/speech-bubble.png";
import FormSuccess from "../../components/loading/FormSuccess";
import Divider from "../Register/components/Divider";
import LoginForm from "./components/LoginForm";
import Relogin from "./components/Relogin";

const Login = () => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(true);
  const [lastUser, setLastUser] = useState<string>(
    Cookies.get("last_user") || ""
  );

  return (
    <div className="flex justify-center items-center h-[100vh] bg-root-bg font-nunito">
      <div className="bg-white px-3 py-10 shadow-lg flexrounded-md text-black min-h-[500px] w-full sm:w-[400px] h-full sm:h-auto">
        {isFormOpen ? (
          <>
            {lastUser ? (
              <Relogin
                id={lastUser}
                setIsFormOpen={setIsFormOpen}
                setLastId={setLastUser}
              />
            ) : (
              <div className="w-full flex flex-col">
                <LazyLoadImage
                  className="w-[60%] lg:flex justify-center items-center mx-auto hidden sm:block"
                  src={Logo}
                  alt="logo"
                  effect="blur"
                />
                <h1 className="text-4xl text-black text-center font-bold font-nunito">
                  CHAT APP
                </h1>
                <h1 className="text-3xl font-semibold text-center sm:hidden mb-10">
                  Connect
                </h1>
                <LoginForm setIsFormOpen={setIsFormOpen} />
              </div>
            )}
          </>
        ) : (
          <FormSuccess message="Connected" redirectTo="chat" />
        )}
      </div>
    </div>
  );
};

export default Login;
