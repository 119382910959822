// import { FC, useState, useTransition } from "react";
// import { useSelector } from "react-redux";

// import Spinner from "../../../components/loading/Spinner";
// import { RootState } from "../../../redux/store";
// import BlockedTab from "./Block/BlockedTab";
// import FriendsTab from "./Friend/FriendsTab";
// import RequestsTab from "./Request/RequestsTab";

// type Props = {
//   profileId: string;
// };

// const Tabs: FC<Props> = ({ profileId }) => {
//   const user = useSelector((state: RootState) => state.auth.user);
//   const [tab, setTab] = useState("friends");
//   const [isPending, startTransition] = useTransition();

//   const handleClickFriends = () => {
//     startTransition(() => {
//       setTab("friends");
//     });
//   };

//   const handleClickRequests = () => {
//     startTransition(() => {
//       setTab("requests");
//     });
//   };

//   const handleClickBlock = () => {
//     startTransition(() => {
//       setTab("blocked");
//     });
//   };

//   return (
//     <div className="max-w-[800px] mx-auto pb-10">
//       <div className="flex justify-around">
//         <span
//           onClick={handleClickFriends}
//           className={`
//                         text-xl py-3 cursor-pointer w-full text-center duration-200 transition-all border-b
//                         ${
//                           tab === "friends"
//                             ? "border-b-2 font-semibold border-[#66a3a6]"
//                             : "hover:border-[#66a3a6] border-[#66a3a6]"
//                         }
//                     `}
//         >
//           Members
//         </span>
//         {user?.id === profileId && (
//           <>
//             <span
//               onClick={handleClickRequests}
//               className={`
//                                 text-xl py-3 cursor-pointer w-full text-center duration-200 transition-all border-b
//                                 ${
//                                   tab === "requests"
//                                     ? "border-b-2 font-semibold border-[#66a3a6]"
//                                     : "hover:border-[#66a3a6] border-[#66a3a6]"
//                                 }
//                             `}
//             >
//               Requests
//             </span>
//             <span
//               onClick={handleClickBlock}
//               className={`
//                                 text-xl py-3 cursor-pointer w-full text-center duration-200 transition-all border-b
//                                 ${
//                                   tab === "blocked"
//                                     ? "border-b-2 font-semibold border-[#66a3a6]"
//                                     : "hover:border-[#66a3a6] border-[#66a3a6]"
//                                 }
//                             `}
//             >
//               Blocked
//             </span>
//           </>
//         )}
//       </div>
//       <div>
//         {isPending ? (
//           <div className="mt-10">
//             <Spinner size="lg" />
//           </div>
//         ) : (
//           <>
//             {tab === "friends" && <FriendsTab />}
//             {tab === "requests" && <RequestsTab />}
//             {tab === "blocked" && <BlockedTab />}
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Tabs;

import { FC, useState, useTransition } from "react";
import { useSelector } from "react-redux";

import Spinner from "../../../components/loading/Spinner";
import { RootState } from "../../../redux/store";
import BlockedTab from "./Block/BlockedTab";
import FriendsTab from "./Friend/FriendsTab";
import RequestsTab from "./Request/RequestsTab";

type Props = {
  profileId: string;
};

const Tabs: FC<Props> = ({ profileId }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [tab, setTab] = useState("friends");
  const [isPending, startTransition] = useTransition();

  const handleClickFriends = () => {
    startTransition(() => {
      setTab("friends");
    });
  };

  const handleClickRequests = () => {
    startTransition(() => {
      setTab("requests");
    });
  };

  const handleClickBlock = () => {
    startTransition(() => {
      setTab("blocked");
    });
  };

  return (
    <div className="max-w-[800px] mx-auto pb-10 bg-white">
      <div className="flex justify-around">
        <span
          onClick={handleClickFriends}
          className={`
                        text-xl py-3 cursor-pointer w-full text-center duration-200 transition-all border-b font-nunito text-black
                        ${
                          tab === "friends"
                            ? "border-b-4 font-semibold border-[#66a3a6]" // Active tab with thicker border
                            : "hover:border-[#66a3a6] border-neutral-400" // Non-active tab
                        }
                    `}
        >
          Members
        </span>
        {user?.id === profileId && (
          <>
            <span
              onClick={handleClickRequests}
              className={`
                                text-xl py-3 cursor-pointer w-full text-center duration-200 transition-all border-b font-nunito text-black
                                ${
                                  tab === "requests"
                                    ? "border-b-4 font-semibold border-[#66a3a6]" // Active tab with thicker border
                                    : "hover:border-[#66a3a6] border-neutral-400" // Non-active tab
                                }
                            `}
            >
              Requests
            </span>
            <span
              onClick={handleClickBlock}
              className={`
                                text-xl py-3 cursor-pointer w-full text-center duration-200 transition-all border-b font-nunito text-black
                                ${
                                  tab === "blocked"
                                    ? "border-b-4 font-semibold border-[#66a3a6]" // Active tab with thicker border
                                    : "hover:border-[#66a3a6] border-neutral-400" // Non-active tab
                                }
                            `}
            >
              Blocked
            </span>
          </>
        )}
      </div>
      <div>
        {isPending ? (
          <div className="mt-10">
            <Spinner size="lg" />
          </div>
        ) : (
          <>
            {tab === "friends" && <FriendsTab />}
            {tab === "requests" && <RequestsTab />}
            {tab === "blocked" && <BlockedTab />}
          </>
        )}
      </div>
    </div>
  );
};

export default Tabs;
