import { FC, ReactNode, useState } from "react";

type Props = {
    children: ReactNode;
}


const ContentArea: FC<Props> = ({ children }) => {
    return (
        <div className="xl:col-span-5 md:col-span-3 h-full overflow-y-auto md:rounded-tr-[28px] md:rounded-br-[28px]">
            {children}
        </div>
    )
}

export default ContentArea;