import axios from "axios";
import { API_BASE_URL } from '../utils/constants';
import axiosWithAuth from "../utils/axiosWithAuth";

export const createAccount = async ({ email, username, password }: signFormType) => {
    try {
        const { data } = await axios.post(`${API_BASE_URL}/api/v1/auth/register`, {
            email,
            username,
            password
        });
        return data;
    } catch {
        return { statusCode: '409', message: 'User already exists.' };
    }
}

export const logIn = async ({ email, password }: signFormType) => {
    try {
        const { data } = await axiosWithAuth.post(`${API_BASE_URL}/api/v1/auth/chat`, {
            email
        });
        return data;
    } catch {
        return { statusCode: '401', message: 'Wrong email or invalid user.' }
    }
}