import { FC, ReactNode } from 'react'

type Props = {
  type?: 'button' | 'submit' | 'reset';
  children: ReactNode;
  isChannel?: boolean;
}

const BasicButton: FC<Props> = ({ type = 'button', children, isChannel }) => {
  return (
    <button
      type={type}
      className={`rounded-md p-3 text-white duration-200 bg-[#66a3a6] font-nunito
        ${isChannel ? 'hover:bg-neutral-600 md:w-4/12 sm:w-6/12  sm:text-sm md:text-base' : 'sm:w-6/12 w-full'}`}
    >
      {children}
    </button>
  )
}

export default BasicButton;
