import axios from "axios";
import axiosWithAuth from "../utils/axiosWithAuth";
import { API_BASE_URL, CLOUD_NAME, UPLOAD_PRESET } from "../utils/constants";
import Cookies from "js-cookie";

export const getUser = async (id: string) => {
  const { data } = await axios.get(`${API_BASE_URL}/api/v1/users/id/${id}`);
  return data;
};

export const getUsersBySearch = async (search: string) => {
  const { data } = await axiosWithAuth.get(`/users/find?search=${search}`);
  return data;
};

export const updateUser = async (id: string, user: any) => {
  const { data } = await axiosWithAuth.put(`/users/id/${id}`, user);
  return data;
};

// IMAGES
export const uploadImages = async (images: any) => {
  const results: any[] = [];
  const token = Cookies.get("access_token");
  for (let i = 0; i < images.length; i++) {
    const formData = new FormData();
    formData.append("file", images[i]);
    formData.append("upload_preset", UPLOAD_PRESET!);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/files/multiple`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    results.push(data.secure_url);
  }

  return results;
};

export const uploadUserImage = async (image: string) => {
  const formData = new FormData();
  formData.append("file", image);
  const { data } = await axiosWithAuth.post(`/files/`, formData);
  return API_BASE_URL + "/api/v1/files/" + data.body[0].filename;
};

// FRIENDS
export const getFriends = async (id: string) => {
  const { data } = await axiosWithAuth.get(`/users/${id}/friend`);
  return data;
};

export const setFriend = async (
  id: string,
  otherId: string,
  status: boolean
) => {
  const { data } = await axiosWithAuth.put(`/users/${id}/friend`, {
    otherId,
    status,
  });
  return data;
};

export const checkFriend = async (userId: string, id: string) => {
  const { data } = await axiosWithAuth.get(`/users/id/${userId}`);
  const friends: string[] = data.user.friends;
  if (!friends) return false;

  const isFriend = friends.includes(id);
  return isFriend;
};

// REQUESTS
export const getRequests = async (id: string) => {
  const { data } = await axiosWithAuth.get(`/users/${id}/request`);
  return data;
};

export const setRequest = async (
  id: string,
  otherId: string,
  status: boolean
) => {
  const { data } = await axiosWithAuth.put(`/users/${id}/request`, {
    otherId,
    status,
  });
  return data;
};

// BLOCK
export const getBlocked = async (id: string) => {
  const { data } = await axiosWithAuth.get(`/users/${id}/block`);
  return data;
};

export const setBlocked = async (
  id: string,
  otherId: string,
  status: boolean
) => {
  const { data } = await axiosWithAuth.put(`/users/${id}/block`, {
    otherId,
    status,
  });
  return data;
};

export const checkBlock = async (userId: string, id: string) => {
  const { data } = await axiosWithAuth.get(`/users/id/${userId}`);
  const blocked: string[] = data.user.blocked;
  if (!blocked) return false;

  const isBlocked = blocked.includes(id);
  return isBlocked;
};
