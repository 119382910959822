import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import Logo from "../../assets/speech-bubble.png";
import FormSuccess from "../../components/loading/FormSuccess";
import Divider from "./components/Divider";
import RegisterForm from "./components/RegisterForm";

const Register = () => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(true);

  return (
    <div className="flex justify-center items-center w-full h-[100vh] bg-root-bg  font-nunito">
      <div className="py-10 md:py-5 w-full h-full sm:h-auto sm:w-[400px] shadow-lg rounded-md text-white flex flex-col justify-center items-center bg-white">
        {isFormOpen ? (
          <>
            <LazyLoadImage
              className="w-[100%] mx-auto hidden sm:block flex items-center justify-center"
              src={Logo}
              alt="logo"
              effect="blur"
            />
            <h1 className="text-4xl text-black text-center font-bold font-nunito">
              CHAT APP
            </h1>
            <h1 className="text-3xl font-semibold text-center sm:hidden mb-10">
              Create Account
            </h1>
            <RegisterForm setIsFormOpen={setIsFormOpen} />
            <div className="text-center mb-3">
              <Divider />
              <Link
                className="text-black hover:text-[#66a3a6] duration-200 font-nunito"
                to="/login"
              >
                Have an account? Login
              </Link>
            </div>
          </>
        ) : (
          <FormSuccess message="Account created" redirectTo="login" />
        )}
      </div>
    </div>
  );
};

export default Register;
