import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IoMdRemoveCircleOutline, IoMdAddCircleOutline } from "react-icons/io";
import { getUser } from "../../../services/userService";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { API_BASE_URL } from "../../../utils/constants";

type Props = {
  user?: User;
  userId?: string;
  isAdded: boolean;
  participants: string[];
  setParticipants: Dispatch<SetStateAction<string[]>>;
  search?: string;
  admins: string[];
  setAdmins: Dispatch<SetStateAction<string[]>>;
};

const UserBar: FC<Props> = ({
  user,
  userId,
  isAdded,
  participants,
  setParticipants,
  search,
  admins,
  setAdmins,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const [participant, setParticipant] = useState<User | null>(
    user ? user : null
  );
  const [participantImage, setParticipantImage] = useState(participant?.image)

  useEffect(() => {
    if (!userId) return;

    const fetchUser = async () => {
      const result = await getUser(userId);
      setParticipant(result.user);
    };

    fetchUser();
  }, [userId, search]);

  useEffect(() => {
    if (!participant) return;

    if (participant.image?.includes("avataaars.io")) {
      setParticipantImage(participant.image);
    } else {
      setParticipantImage(`${API_BASE_URL}/api/v1/files/${participant?.image}`);
    }
  }, [participant]);

  const handleClick = () => {
    if (isAdded) {
      const newParticipants = participants.filter((p: string) => {
        return p !== participant?.id;
      });

      if (admins?.includes(participant?.id!)) {
        const newAdmins = admins?.filter((admin) => {
          return admin !== participant?.id;
        });

        setAdmins(newAdmins!);
      }

      setParticipants(newParticipants);
    } else {
      setParticipants((prev) => [...prev, participant?.id!]);
    }
  };

  const handleAddAdmin = () => {
    if (!setAdmins) return;

    if (admins?.includes(participant?.id!)) {
      const newAdmins = admins?.filter((admin) => {
        return admin !== participant?.id;
      });

      return setAdmins(newAdmins!);
    }

    return setAdmins((prev) => [...prev, participant?.id!]);
  };



  return (
    <div
      className={`
                w-full items-center bg-createChannel-bg rounded-md p-3 my-3
                ${
                  search && !isAdded
                    ? participant?.username
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                      ? "flex"
                      : "hidden"
                    : "flex"
                }
            `}
    >
      <LazyLoadImage
        src={participantImage}
        alt="participant"
        className="w-12 h-12 rounded-full"
        effect="blur"
      />
      <p className="ml-2 text-black font-nunito font-semibold">
        {participant?.username}
      </p>
      <div className="ml-auto">
        <button
          onClick={handleClick}
          type="button"
          className="bg-createChannel-bg text-3xl hover:bg-neutral-600 p-2 rounded-md text-black"
        >
          {isAdded ? <IoMdRemoveCircleOutline /> : <IoMdAddCircleOutline />}
        </button>
        {isAdded && (
          <button
            onClick={handleAddAdmin}
            type="button"
            className="bg-createChannel-bg text-3xl hover:bg-neutral-600 p-2 rounded-md text-black"
            disabled={currentUser?.id === user}
          >
            {admins?.includes(participant?.id!) ? (
              <AiFillStar />
            ) : (
              <AiOutlineStar />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default UserBar;
