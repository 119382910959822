import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'
import { RxDotsVertical } from 'react-icons/rx';
import { FaUserFriends } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IoPersonAddSharp } from 'react-icons/io5';
import { MdAddToPhotos } from 'react-icons/md';

import { RootState } from '../../../redux/store'
import { logOut } from '../../../redux/features/authSlice';
import { getUser } from '../../../services/userService';
import { API_BASE_URL } from '../../../utils/constants';

const UserBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [loggedUser, setLoggedUser] = useState<User>();

  useEffect(() => {
    const fetchUser = async () => {
      const result = await getUser(user?.id!);
      setLoggedUser(result.user);
    }

    fetchUser();
  }, [user?.id]);

  let imgPath;
  if (loggedUser?.image?.includes("avataaars.io")) {
    imgPath = loggedUser?.image;
  } else {
    imgPath = API_BASE_URL + '/api/v1/files/' + loggedUser?.image;
  }

  return (
    <div className='p-3 flex items-center relative h-22 bg-[#66a3a6]
' >
      <LazyLoadImage
        onClick={() => navigate('/profile', { state: { userId: user?.id } })}
        className='w-16 h-16 object-cover rounded-full cursor-pointer'
        src={imgPath}
        alt='user-pp'
        effect='blur'
      />
      <p className='ml-3 text-lg w-32 sm:w-64 md:w-40 lg:w-52 xl:w-56 h-7 overflow-hidden text-white font-nunito'>{loggedUser?.username}</p>
      <div className=' ml-auto cursor-pointer group'>
        <RxDotsVertical className='text-2xl text-white' />
        <div className='absolute group-hover:block hidden text-white w-full lg:max-w-[220px] md:w-auto bg-white  shadow-md rounded-md z-50 sm:top-20 md:top-10 sm:right-2 sm:w-[50%]'>
          <button
            className='text-black w-full hover:bg-custom-bg duration-200 py-3 px-8 flex items-center'
            onClick={() => navigate('/create')}
          >
            <MdAddToPhotos className='mr-3' />
            Create Channel
          </button>
          <button
            className='text-black w-full hover:bg-custom-bg duration-200 p-3 px-8 flex items-center'
            onClick={() => navigate('/addfriend')}
          >
            <IoPersonAddSharp className='mr-3' />
            Add Member
          </button>
          <button
            className='text-black w-full hover:bg-custom-bg duration-200 p-3 px-8 flex items-center'
            onClick={() => navigate('/profile', { state: { userId: user?.id } })}
          >
            <FaUserFriends className='mr-3' />
            Members
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserBox