import { useRef, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Participants from "./Participants";
import BasicButton from "../../../components/buttons/BasicButton";
import { RootState } from "../../../redux/store";
import { createChannel } from "../../../services/channelService";
import { uploadUserImage } from "../../../services/userService";
import { setRefresh } from "../../../redux/features/channelSlice";
import { NO_AVATAR_CHANNEL } from "../../../utils/constants";
import { ReactComponent as UploadPicture } from "../../../assets/uploadimage.svg";

const ChannelForm = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const inputRef = useRef<any>(null);
  const [image, setImage] = useState<any>(null);
  const [participants, setParticipants] = useState<string[]>([user?.id!]);
  const [admins, setAdmins] = useState<string[]>([user?.id!]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    var secureUrl = NO_AVATAR_CHANNEL;

    if (e.target.image.files.length === 1) {
      console.log(e.target.image.files);
      secureUrl = await uploadUserImage(e.target.image.files[0]);
    }

    const { statusCode, message } = await createChannel({
      name: e.target.name.value,
      participants,
      admins,
      description: e.target.description.value,
      image: secureUrl,
    });

    if (statusCode === "201") {
      setParticipants([user?.id!]);
      setAdmins([user?.id!]);
      setImage(null);
      e.target.reset();
      dispatch(setRefresh());

      return toast.success(message, {
        duration: 3000,
        position: "bottom-center",
        style: {
          backgroundColor: "#353535",
          color: "#fff",
        },
      });
    }

    toast.error(message, {
      duration: 3000,
      position: "bottom-center",
      style: {
        backgroundColor: "#353535",
        color: "#fff",
      },
    });
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChange = (e: any) => {
    const imageFile = e.target.files[0];

    if (imageFile && FileReader) {
      const fr = new FileReader();
      fr.onload = () => {
        setImage(fr.result);
      };
      fr.readAsDataURL(imageFile);
    }
  };

  return (
    <div style={{ height: "90.99%", paddingTop: "4%" }}>
      <form
        action="POST"
        className="max-w-[800px] px-3 mx-auto overflow-y-auto overflow-x-hidden border sm:bg-white"
        onSubmit={handleSubmit}
      >
        <div className="flex items-center justify-center w-full lg:flex-row flex-col py-5 border-b border-neutral-600 relative">
          <LazyLoadImage
            className={`rounded-full md:w-52 md:h-52 object-cover cursor-pointer duration-200 sm:w-[9rem] sm:h-[9rem]  ${
              !image &&
              "border-neutral-600 bg-custom-bg hover:bg-createChannel-bg"
            }`}
            src={image}
            alt="ch"
            onClick={handleClick}
          />
          <input
            onChange={handleChange}
            ref={inputRef}
            type="file"
            hidden
            name="image"
            accept="image/png, image/jpeg"
          />
          <UploadPicture className="absolute  lg:top-[62%] lg:left-[25%] sm:top-[26%] sm:left-[60%]" />
          <div className="lg:w-4/6 md:pl-3 lg:pl-5 md:w-[350px] sm:w-[92%]">
            <div className="flex flex-col mb-3">
              <label htmlFor="name" className="text-black font-nunito">
                Name
              </label>
              <input
                className="rounded-md p-2 text-black font-nunito outline-none bg-createChannel-bg"
                placeholder="Channel Name"
                maxLength={50}
                type="text"
                name="name"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="description" className="text-black font-nunito">
                Description
              </label>
              <textarea
                name="description"
                placeholder="Channel Description"
                className="bg-createChannel-bg p-2 font-nunito text-black resize-none rounded-md outline-none"
                maxLength={255}
                cols={20}
                rows={5}
              />
            </div>
          </div>
        </div>
        <Participants
          participants={participants}
          setParticipants={setParticipants}
          admins={admins}
          setAdmins={setAdmins}
        />
        <div className="p-3 lg:p-0 flex justify-center">
          <BasicButton type="submit" isChannel={true}>
            Create Channel
          </BasicButton>
        </div>
        <Toaster />
      </form>
    </div>
  );
};

export default ChannelForm;
